import React from "react";
import {Card, Col, Container, ListGroup, Row, Table} from "react-bootstrap";

const FunctionalAnalysisPage: React.FC = () => {

    const students = [
        {name: "Аккузина Анастасия", five: 51, tr: 0},
        {name: "Герасимов Александр", five: 54, tr: 0},
        {name: "Голышев Алексей", five: 21, tr: 0},
        {name: "Гурьев Лев", five: 0, tr: 0},
        {name: "Денисов Никита", five: 26, tr: 0},
        {name: "Кемаев Иван", five: 55, tr: 0},
        {name: "Киданов Владислав", five: 0, tr: 0},
        {name: "Косинов Михаил", five: 44, tr: 0},
        {name: "Кривенцов Ярослав", five: 45, tr: 0},
        {name: "Кулаева Наталья", five: 64, tr: 0},
        {name: "Лазарев Даниил", five: 0, tr: 0},
        {name: "Лобов Александр", five: 64, tr: 0},
        {name: "Максимов Герман", five: 37, tr: 0},
        {name: "Максимова Анастасия", five: 65, tr: 1},
        {name: "Мелехин Александр", five: 0, tr: 0},
        {name: "Мхитарян Арам", five: 56, tr: 0},
        {name: "Носенкова Софья", five: 18, tr: 0},
        {name: "Острецов Владимир", five: 23, tr: 0},
        {name: "Первушина Карина", five: 50, tr: 0},
        {name: "Понамарёв Никита", five: 47, tr: 0},
        {name: "Прилепов Владислав", five: 34, tr: 0},
        {name: "Родин Владислав", five: 0, tr: 0},
        {name: "Сумбаев Никита", five: 28, tr: 0},
        {name: "Толоконников Василий", five: 44, tr: 0},
        {name: "Хасьянов Артём", five: 0, tr: 0},
        {name: "Чуйкин Тимофей", five: 10, tr: 0},
        {name: "Шевыров Аркадий", five: 59, tr: 0},
        {name: "Ярыгин Егор", five: 47, tr: 0}
    ];

    return (
        <Container className="my-4">
            {/* Заголовок страницы */}
            <Row className="mb-4">
                <Col>
                    <h1 className="text-center">Функциональный анализ</h1>
                </Col>
            </Row>

            {/* Требования для сдачи */}
            <Row className="mb-4">
                <Col>
                    <Card className="shadow-sm border-light rounded-3">
                        <Card.Header className="text-center">
                            <h4>Требования для сдачи</h4>
                        </Card.Header>
                        <Card.Body>
                            <ListGroup variant="flush">
                                <ListGroup.Item>Five minute tasks</ListGroup.Item>
                                <ListGroup.Item>Типовик</ListGroup.Item>
                                <ListGroup.Item>Экзамен</ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Выполненные требования (таблица) */}
            <Row className="mb-4">
                <Col>
                    <Card className="shadow-sm border-light rounded-3">
                        <Card.Header className="text-center">
                            <h4>Выполненные требования</h4>
                        </Card.Header>
                        <Card.Body>
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>№</th>
                                    <th>ФИО</th>
                                    <th>5MT</th>
                                    <th>Вар</th>
                                    <th>ТР</th>
                                </tr>
                                </thead>
                                <tbody>
                                {students.map((value, index) => (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{value.name}</td>
                                        <td>{value.five}/65</td>
                                        <td>{(index) % 24 + 1}</td>
                                        <td>{value.tr}/4</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Решения 5 min Task */}
            <Row className="mb-4">
                <Col>
                    <Card className="shadow-sm border-light rounded-3">
                        <Card.Header className="text-center">
                            <h4>Решения 5 min Task</h4>
                        </Card.Header>
                        <Card.Body>
                            <ListGroup variant="flush">
                                <ListGroup.Item>№1 Задача - Решение</ListGroup.Item>
                                <ListGroup.Item>№2 Задача - Решение</ListGroup.Item>
                                <ListGroup.Item>№3 Задача - Решение</ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Решения типовиков */}
            <Row className="mb-4">
                <Col>
                    <Card className="shadow-sm border-light rounded-3">
                        <Card.Header className="text-center">
                            <h4>Решение типовиков</h4>
                        </Card.Header>
                        <Card.Body>
                            <p>Список решений типовиков...</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Решения экзаменационных билетов */}
            <Row className="mb-4">
                <Col>
                    <Card className="shadow-sm border-light rounded-3">
                        <Card.Header className="text-center">
                            <h4>Решения экзаменационных билетов</h4>
                        </Card.Header>
                        <Card.Body>
                            <p>Список решений экзаменационных билетов...</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Лекции */}
            <Row className="mb-4">
                <Col>
                    <Card className="shadow-sm border-light rounded-3">
                        <Card.Header className="text-center">
                            <h4>Лекции</h4>
                        </Card.Header>
                        <Card.Body>
                            <p>Список лекций...</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Семинары */}
            <Row>
                <Col>
                    <Card className="shadow-sm border-light rounded-3">
                        <Card.Header className="text-center">
                            <h4>Семинары</h4>
                        </Card.Header>
                        <Card.Body>
                            <p>Список семинаров...</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default FunctionalAnalysisPage;
