import React from 'react';
import {Button, Card, Container, Table} from 'react-bootstrap';
import {FaClipboard, FaCrown, FaEnvelope, FaTelegramPlane, FaVk} from "react-icons/fa";

const ContactsPage: React.FC = () => {
    const groupEmail = " kmbo-04-22@sumirea.ru";
    const leaderContact = {
        name: "Толоконников Василий",
        telegram: "WasyaTyan",
        vk: "vasa_tyan"
    };

    const students = [
        {name: "Аккузина Анастасия", telegram: "mine_evo_bot", vk: "akkuzina2001"},
        {name: "Герасимов Александр", telegram: "shiki0n", vk: "gekyume"},
        {name: "Голышев Алексей", telegram: "h01ym01y", vk: "holyphysics"},
        {name: "Гурьев Лев", telegram: "levnojoke", vk: "levgurev"},
        {name: "Денисов Никита", telegram: "MechMath2024", vk: "polymenalpalindrom"},
        {name: "Иванов Е.", telegram: "", vk: ""},
        {name: "Кемаев Иван", telegram: "iv_kem", vk: "iv_kem"},
        {name: "Киданов Владислав", telegram: "properjack", vk: "id268118232"},
        {name: "Косинов Михаил", telegram: "bruhhgg", vk: "mkosinov1"},
        {name: "Кривенцов Ярослав", telegram: "Tregein", vk: "animov90"},
        {name: "Кулаева Наталья", telegram: "kitn112", vk: "kitn113"},
        {name: "Лазарев Даниил", telegram: "debugging_pass", vk: "id389458544"},
        {name: "Лобов Александр", telegram: "", vk: "seeyaspace"},
        {name: "Максимов Герман", telegram: "German_Max", vk: "german_maximov"},
        {name: "Максимова Анастасия", telegram: "maximova_anastasiia", vk: "maximcha11"},
        {name: "Мелехин Александр", telegram: "xxxsani", vk: "xsani"},
        {name: "Мхитарян Арам", telegram: "mkhitarX", vk: "arammkhitaryan04"},
        {name: "Носенкова Софья", telegram: "soff_ffya", vk: "xu_ylo"},
        {name: "Острецов Владимир", telegram: "wllllllllld", vk: "wldossssst"},
        {name: "Первушина Карина", telegram: "yanevtiltebtw", vk: "weryill"},
        {name: "Понамарёв Никита", telegram: "majornp", vk: "majornp"},
        {name: "Прилепов Владислав", telegram: "Jibunnoeiko", vk: "erqfl"},
        {name: "Родин Владислав", telegram: "", vk: ""},
        {name: "Сумбаев Никита", telegram: "Allewrum", vk: "id171755115"},
        {name: "Толоконников Василий", telegram: "WasyaTyan", vk: "vasa_tyan"},
        {name: "Хасьянов Артём", telegram: "", vk: "ime_fomilia"},
        {name: "Чуйкин Тимофей", telegram: "@brgxd", vk: "brgxddd"},
        {name: "Шевыров Аркадий", telegram: "ArkadiyShevyrov", vk: "arkadiy.shevyrov"},
        {name: "Ярыгин Егор", telegram: "weersel7", vk: "weersel69"}
    ];

    return (
        <Container className="my-4">
            <h2>Контакты группы</h2>

            {/* Почта группы */}
            <Card className="mb-4 shadow-sm border-light">
                <Card.Body>
                    <Card.Title className="d-flex align-items-center">
                        <FaEnvelope className="me-2" style={{color: '#007bff'}}/>
                        <span>Почта группы:</span>
                    </Card.Title>
                    <div className="d-flex justify-content-between align-items-center">
                        {/* Ссылка для отправки письма */}
                        <a href={`mailto:${groupEmail}`} className="btn btn-outline-primary btn-sm">
                            Написать на {groupEmail}
                        </a>

                        {/* Кнопка для копирования */}
                        <Button
                            variant="outline-secondary"
                            className="btn-sm ms-2"
                            onClick={() => navigator.clipboard.writeText(groupEmail)}
                            title="Скопировать почту"
                        >
                            <FaClipboard/>
                        </Button>
                    </div>
                </Card.Body>
            </Card>


            {/* Контакты старосты */}
            <Card className="mb-4 shadow-lg border-light rounded-3">
                <Card.Body>
                    <Card.Title className="d-flex align-items-center">
                        <span className="me-3" style={{fontSize: '1.25rem', fontWeight: 'bold', color: '#5A5A5A'}}>Контактные данные старосты:</span>
                    </Card.Title>
                    <Card.Text>
                        <div className="d-flex align-items-center mb-3">
                            <FaCrown className="me-3" style={{color: '#FFD700', fontSize: '1.5rem'}}/>
                            <strong style={{fontSize: '1.25rem', color: '#343A40'}}> {leaderContact.name}</strong>
                            <FaCrown className="ms-3" style={{color: '#FFD700', fontSize: '1.5rem'}}/>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <FaTelegramPlane className="me-3" style={{color: '#0088cc', fontSize: '1.5rem'}}/>
                            <span className="text-muted" style={{fontSize: '1.1rem'}}>Telegram: </span>
                            <a href={`https://t.me/${leaderContact.telegram}`}
                               target="_blank"
                               className="ms-2 text-decoration-none"
                               style={{color: '#0088cc', fontWeight: 'bold'}}
                               rel="noopener noreferrer">
                                @{leaderContact.telegram}
                            </a>
                        </div>
                        <div className="d-flex align-items-center">
                            <FaVk className="me-3" style={{color: '#4c75a3', fontSize: '1.5rem'}}/>
                            <span className="text-muted" style={{fontSize: '1.1rem'}}>VK: </span>
                            <a href={`https://vk.com/${leaderContact.vk}`} target="_blank" rel="noopener noreferrer"
                               className="ms-2 text-decoration-none" style={{color: '#4c75a3', fontWeight: 'bold'}}>
                                @{leaderContact.vk}
                            </a>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>

            {/* Таблица студентов */}
            <Card>
                <Card.Body>
                    <Card.Title>Контакты студентов</Card.Title>
                    <Table striped bordered hover responsive>
                        <thead>
                        <tr>
                            <th>№</th>
                            <th>ФИО</th>
                            <th>
                                Tg
                            </th>
                            <th>
                                VK
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {students.map((student, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{student.name}</td>
                                <td>
                                    {student.telegram ? (
                                        <a href={`https://t.me/${student.telegram}`}
                                           target="_blank"
                                           className="ms-2 text-decoration-none"
                                           style={{color: '#0088cc', fontWeight: 'bold'}}
                                           rel="noopener noreferrer">
                                            <FaTelegramPlane className="me-3"
                                                             style={{color: '#0088cc', fontSize: '1.5rem'}}/>
                                        </a>
                                    ) : (
                                        <span
                                            style={{
                                                color: '#6c757d',
                                                fontStyle: 'italic',
                                                fontWeight: 'bold',
                                                padding: '2px 6px',
                                                borderRadius: '5px',
                                                backgroundColor: '#f8f9fa',
                                                boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)',
                                                textTransform: 'uppercase',
                                                fontSize: '0.9rem',
                                                whiteSpace: 'nowrap'
                                            }}>
                                            No
                                        </span>
                                    )}
                                </td>
                                <td>
                                    {student.vk ? (
                                        <a href={`https://vk.com/${student.vk}`}
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           className="text-decoration-none"
                                           style={{color: '#4c75a3', fontWeight: 'bold'}}>
                                            <FaVk className="me-3" style={{color: '#4c75a3', fontSize: '1.5rem'}}/>
                                        </a>
                                    ) : (
                                        <span
                                            style={{
                                                color: '#6c757d',
                                                fontStyle: 'italic',
                                                fontWeight: 'bold',
                                                padding: '2px 6px',
                                                borderRadius: '5px',
                                                backgroundColor: '#f8f9fa',
                                                boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)',
                                                textTransform: 'uppercase',
                                                fontSize: '0.9rem',
                                                whiteSpace: 'nowrap'
                                            }}>
                                            No
                                        </span>
                                    )}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default ContactsPage;
